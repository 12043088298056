import tournament from "../assets/images/bg/tournament.png";
import apexdogs from "../assets/images/bg/apexdogs.jpg";
import cerberus from "../assets/images/bg/cerberus.gif"

import soundtrack0 from "../assets/sounds/music/beauty.mp3";
import soundtrack1 from "../assets/sounds/music/heroic.mp3"; // Base 
import soundtrack2 from "../assets/sounds/music/dragonslayer.mp3"; // Manta 
import soundtrack3 from "../assets/sounds/music/pandemia.mp3"; // Core 
import soundtrack4 from "../assets/sounds/music/epic.mp3"; // Mantle
import soundtrack5 from "../assets/sounds/music/durandal.mp3"; //Scroll
import soundtrack6 from "../assets/sounds/music/film.mp3"; // Arbitrum 
import soundtrack7 from "../assets/sounds/music/pure-epicness.mp3"; // Neon 
import soundtrack8 from "../assets/sounds/music/endlessstorm.mp3"; // Polygon
import soundtrack9 from "../assets/sounds/music/epicstory.mp3"; // Linea
import soundtrack10 from "../assets/sounds/music/executioner.mp3"; // Pulsechain 
import soundtrack11 from "../assets/sounds/music/film.mp3"; // Opbnb 
import soundtrack12 from "../assets/sounds/music/resurgence.mp3"; // zkFair
import soundtrack13 from "../assets/sounds/music/perfection.mp3";
import soundtrack14 from "../assets/sounds/music/epicsaga.mp3";
import soundtrack15 from "../assets/sounds/music/iceland.mp3"; 
import soundtrack16 from "../assets/sounds/music/masterfulheroes.mp3"; 
import soundtrack17 from "../assets/sounds/music/powerful.mp3" 
import soundtrack18 from "../assets/sounds/music/pandemia.mp3" //unichain
import soundtrack19 from "../assets/sounds/music/masterfulheroes.mp3" //Soneium
import windhowl from "../assets/sounds/music/windhowl.mp3";
import brick from "../assets/sounds/effects/bricksound.mp3";
import concrete from "../assets/sounds/effects/concrete.mp3";
import welcome from "../assets/sounds/effects/welcome.mp3";
import thunder from "../assets/sounds/effects/thunder.mp3";
import rumble from "../assets/sounds/effects/rumbling.mp3";
import woosh from "../assets/sounds/effects/whoosh.mp3";
import activate from "../assets/sounds/effects/activated.mp3";
import stake from "../assets/sounds/effects/staked.mp3";
import claim from "../assets/sounds/effects/tokenclaim.mp3";
import withdraw from "../assets/sounds/effects/tokenwithdraw.mp3";
import fastwhoosh from "../assets/sounds/effects/fastwhoosh.mp3";
import energy from "../assets/sounds/effects/energy.mp3";
import alpenhorn from "../assets/sounds/effects/alpenhorn.mp3";
import pop from "../assets/sounds/effects/pop.mp3";
import longhowl from "../assets/sounds/effects/longhowl.mp3";
import lurkinggrowl from "../assets/sounds/effects/lurkinggrowl.mp3";
import trailinggrowl from "../assets/sounds/effects/trailinggrowl.mp3";
import button from "../assets/sounds/effects/button1.mp3";
import login from "../assets/sounds/effects/login.mp3";
import logoff from "../assets/sounds/effects/logoff.mp3";

const bgmusic = {
    Soundtrack0: soundtrack0,
    Soundtrack1: soundtrack1,
    Soundtrack2: soundtrack2,
    Soundtrack3: soundtrack3,
    Soundtrack4: soundtrack4,
    Soundtrack5: soundtrack5,
    Soundtrack6: soundtrack6,
    Soundtrack7: soundtrack7,
    Soundtrack8: soundtrack8,
    Soundtrack9: soundtrack9,
    Soundtrack10: soundtrack10,
    Soundtrack11: soundtrack11,
    Soundtrack12: soundtrack12,
    Soundtrack13: soundtrack13,
    Soundtrack14: soundtrack14,
    Soundtrack15: soundtrack15,
    Soundtrack16: soundtrack16,
    Soundtrack17: soundtrack17,
    Soundtrack18: soundtrack18,
    Soundtrack19: soundtrack19,
    Windhowl: windhowl,
  };
  
  const soundeffects = {
    Brick: brick,
    Concrete: concrete,
    Welcome: welcome,
    Thunder: thunder,
    Rumble: rumble,
    Whoosh: woosh,
    Activate: activate,
    Stake: stake,
    Claim: claim,
    Withdraw: withdraw,
    Fastwhoosh: fastwhoosh,
    Energy: energy,
    Alpenhorn: alpenhorn,
    Pop: pop,
    Longhowl: longhowl,
    Lurkinggrowl: lurkinggrowl,
    Trailinggrowl: trailinggrowl,
    Button: button,
    Login: login,
    Logoff: logoff,
  };
  
  const backgroundImages = [
  tournament,
  apexdogs,
  cerberus
  ]

  export { bgmusic, soundeffects, backgroundImages };